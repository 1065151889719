import React, { useEffect } from 'react';
import { format } from 'date-fns';

import { Input } from 'components/ui/Form/Input';

export default function DatePicker({
  id,
  pickerType = 'common',
  value,
  onChange,
  className,
  isDisabled,
  extraProps
}: {
  id: string;
  pickerType?: string;
  value: Date | null;
  onChange: (date: Date | null) => void;
  className?: string,
  isDisabled?: boolean,
  extraProps?: Record<string, any>
}) {
  const cleanedId = id.replace(/\s+/g, '-');

  useEffect(() => {
    const config = {
      weekdaysShort: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      format: 'dd / mm / yyyy',
      labelMonthNext: 'Go to the next month',
      labelMonthPrev: 'Go to the previous month',
      labelMonthSelect: 'Pick a month from the dropdown',
      labelYearSelect: 'Pick a year from the dropdown',
      onSet: (context: any) => {
        onChange(context?.select ? new Date(context.select): null);
      },
    };

    let override = {};

    switch (pickerType) {
      case 'past': {
        override = {
          selectMonths: true,
          selectYears: 100,
          max: 'Today',
          today: '',
        };

        break;
      }
      case 'future': {
        override = {
          selectMonths: true,
          selectYears: true,
          today: 'Today',
          clear: 'Clear',
          close: 'Ok',
          closeOnSelect: false,
          min: true,
        };

        break;
      }
      default: {
        override = {
          selectMonths: true,
          selectYears: 100,
        };

        break;
      }
    }

    // @ts-ignore
    $(`#${cleanedId}`).pickadate({ ...config, ...override, ...extraProps });
  }, [pickerType]);

  return <Input disabled={isDisabled} className={className} id={cleanedId} type="text" data-value={value ? format(value, 'dd / MM / yyyy'): undefined} />;
}
