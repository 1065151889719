import CreateWorkflowSchema, { CreateWorkflowSchemaContract } from 'schemas/CreateWorkflowSchema';
import GetWorkflowPendingRequestsSchema, {
  GetWorkflowPendingRequestsContract,
} from 'schemas/GetWorkflowPendingRequests';
import GetWorkflowRequestsSchema, {
  GetWorkflowRequestsContract,
} from 'schemas/GetWorkflowRequests';
import GetWorkflowSummaryForUserSchema, {
  GetWorkflowSummaryForUserContract,
} from 'schemas/GetWorkflowSummaryForUser';
import GetWorkflowsSchema, { GetWorkflowsSchemaContract } from 'schemas/GetWorkflowsSchema';
import SuccessResponseSchema, {
  SuccessResponseSchemaContract,
} from 'schemas/SuccessResponseSchema';
import WorkflowRequestApprovalSchema, {
  WorkflowRequestApprovalSchemaContract,
} from 'schemas/WorkflowRequestApprovalSchema';
import { deleteApi, getApi, graphQLApi, postApi, putApi } from 'utils/Api';
import { format } from 'date-fns';
import GetWorkflowRequestDetailsSchema, {
  GetWorkflowRequestDetailsContract,
} from 'schemas/GetWorkflowRequestDetails';
import {
  GetWorkflowRequestDetailsGQLRequest,
  GetWorkflowRequestDetailsGQLResponse,
  GetWorkflowRequestGQLRequest,
  GetWorkflowRequestsGQLResponse,
} from './gql-utils/workflows/types';
import { getWorkflowRequestQuery, getWorkflowRequestsQuery } from './gql-utils/workflows/queries';

export interface CreateWorkflowRequestData {
  action_type: number;
  approvers: {
    level: number;
    people_ids: number[];
    min_approvals: number;
  }[];
}

export interface CommonWorkflowRequestData {
  ids: number[];
}

export interface ApprovalRequestData extends CommonWorkflowRequestData {
  remarks?: string;
}

export interface RejectionRequestData extends CommonWorkflowRequestData {
  remarks: string;
}

export type WithdrawalRequestData = CommonWorkflowRequestData;

export interface GetWorkflowRequestsData {
  action_type: number;
  from_date?: string | null;
  to_date?: string | null;
  page?: number | null;
  limit?: number | null;
  is_initiated_by_me?: 1 | 0 | null;
  is_my_approval?: 1 | 0 | null;
  ['status[]']?: string[] | null;
  level?: number | null;
}

export interface GetWorkflowRequestDetailsRequestData {
  requestId: number;
  isGraphQLEnabled: boolean;
}

const workflowApis = {
  createWorkflow: (requestData: CreateWorkflowRequestData) =>
    postApi<CreateWorkflowSchemaContract, any>({
      url: 'workflow',
      responseSchema: CreateWorkflowSchema,
      requestData,
    }),
  updateWorkflow: ({
    workflowId,
    requestData,
  }: {
    workflowId: number;
    requestData: CreateWorkflowRequestData;
  }) =>
    putApi<CreateWorkflowSchemaContract, any>({
      url: `workflow/${workflowId}`,
      responseSchema: CreateWorkflowSchema,
      requestData,
    }),
  deleteWorkflow: ({ workflowId }: { workflowId: number }) =>
    deleteApi<SuccessResponseSchemaContract, any>({
      url: `workflow/${workflowId}`,
      responseSchema: SuccessResponseSchema,
    }),
  getAllWorkflows: () =>
    getApi<GetWorkflowsSchemaContract>({
      url: 'workflow',
      responseSchema: GetWorkflowsSchema,
    }),
  approveRequests: (requestData: ApprovalRequestData) =>
    postApi<WorkflowRequestApprovalSchemaContract, any>({
      url: 'workflow-requests/approve',
      responseSchema: WorkflowRequestApprovalSchema,
      requestData,
    }),
  rejectRequests: (requestData: RejectionRequestData) =>
    postApi<WorkflowRequestApprovalSchemaContract, any>({
      url: 'workflow-requests/reject',
      responseSchema: WorkflowRequestApprovalSchema,
      requestData,
    }),
  withdrawRequests: (requestData: WithdrawalRequestData) =>
    postApi<WorkflowRequestApprovalSchemaContract, any>({
      url: 'workflow-requests/withdraw',
      responseSchema: WorkflowRequestApprovalSchema,
      requestData,
    }),
  getRequests: (requestData: GetWorkflowRequestsData, isGraphQLEnabled = false) => {
    const requestDataCopy = { ...requestData };
    delete requestDataCopy['status[]'];

    if (requestDataCopy.from_date) {
      requestDataCopy.from_date = format(new Date(requestDataCopy.from_date), 'Y-MM-dd');
    }

    if (requestDataCopy.to_date) {
      requestDataCopy.to_date = format(new Date(requestDataCopy.to_date), 'Y-MM-dd');
    }

    let queryParams = new URLSearchParams(
      requestDataCopy as unknown as Record<string, string>,
    ).toString();

    let statusString = '';
    if (requestData['status[]']) {
      statusString = requestData['status[]'].reduce(
        (prev, current) => `${prev}&status[]=${current}`,
        '',
      );

      queryParams += statusString;
    }

    if (isGraphQLEnabled) {
      const queryVariables = {
        fromDate: requestDataCopy.from_date,
        toDate: requestDataCopy.to_date,
        page: requestDataCopy.page,
        limit: requestDataCopy.limit,
        isInitiatedByMe: requestDataCopy.is_initiated_by_me,
        isMyApproval: requestDataCopy.is_my_approval,
        status: statusString,
        level: requestDataCopy.level,
        actionType: requestDataCopy.action_type,
      };

      return graphQLApi<GetWorkflowRequestsGQLResponse, GetWorkflowRequestGQLRequest>({
        query: getWorkflowRequestsQuery,
        queryVariables,
      });
    } else {
      return getApi<GetWorkflowRequestsContract>({
        url: `workflow-requests?${queryParams}`,
        responseSchema: GetWorkflowRequestsSchema,
      });
    }
  },
  getWorkflowSummaryForUser: (forUserId?: number) => {
    let url = 'workflow/summary';

    if (forUserId) {
      const queryParams = new URLSearchParams();
      queryParams.set('forUser', forUserId.toString());

      url = 'workflow/summary?' + queryParams.toString();
    }

    return getApi<GetWorkflowSummaryForUserContract>({
      url: url,
      responseSchema: GetWorkflowSummaryForUserSchema,
    });
  },
  getPendingWorkflowRequests: (workflowId: number) =>
    getApi<GetWorkflowPendingRequestsContract>({
      url: `workflow/${workflowId}/pending`,
      responseSchema: GetWorkflowPendingRequestsSchema,
    }),
  getWorkflowRequestDetails: ({
    requestId,
    isGraphQLEnabled,
  }: GetWorkflowRequestDetailsRequestData) => {
    const queryVariables = {
      workflowRequestId: requestId,
    };

    if (isGraphQLEnabled) {
      return graphQLApi<GetWorkflowRequestDetailsGQLResponse,  GetWorkflowRequestDetailsGQLRequest>({
        query: getWorkflowRequestQuery,
        queryVariables
      })
    } else {
      return getApi<GetWorkflowRequestDetailsContract>({
        url: `workflow-requests/${requestId}`,
        responseSchema: GetWorkflowRequestDetailsSchema,
      });
    }
  },
};

export { workflowApis };
