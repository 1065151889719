const reportConstants = Object.freeze({
  ATTRIBUTE_SALARY: 'salary',
  ATTRIBUTE_COMPLIANCE: 'compliance',
  ATTRIBUTE_COUNT: 'count',
  TIMELINE_3_MONTHS: '3months',
  TIMELINE_6_MONTHS: '6months',
  TIMELINE_12_MONTHS: '12months',
  TIMELINE_CURRENT_QUARTER: 'cquarter',
  TIMELINE_LAST_QUARTER: 'lquarter',
  TIMELINE_LAST_YEAR: 'lyear',
  TIMELINE_CURRENT_YEAR: 'cyear',
  TIMELINE_FY_2019:'fy2019',
  TIMELINE_FY_2020:'fy2020',
  TIMELINE_FY_2021:'fy2021',
  TIMELINE_FY_2022:'fy2022',
  
});

export default reportConstants;
