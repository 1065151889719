import React from 'react';
import { NotificationApiSchemaContract } from 'schemas/NotificationApiSchema';
import { getIconForNotificationType } from './constants';
import notificationDate from 'assets/notification--date.svg';
import styles from './index.module.scss';
import {getStaticMediaUrl} from 'utils/Urls';

export function NotificationItem({ data }: { data: NotificationApiSchemaContract[0] }) {
  let redirectURL = data.url;
  if (redirectURL) {
    return (
      <>
        <a href={redirectURL} className="px-6 py-5">
          <NotificationItemChild data={data} />
        </a>
      </>
    );
  } else {
    return (
      <li className="px-8 py-8">
        <NotificationItemChild data={data} />
      </li>
    );
  }
}

export function NotificationItemChild({ data }: { data: NotificationApiSchemaContract[0] }) {
  let icon = getIconForNotificationType(data.type);
  return (
    <>
      <div className="mr-6">
        <img src={data.readStatus === true ? getStaticMediaUrl(icon.read) : getStaticMediaUrl(icon.unread)} />
      </div>
      <div className="flex-1 ml-20 -mt-16">
        <div className="text-white font-semibold text-1.5xl">{data.title}</div>
        <div className="text-white-o-80 text-lg pt-2">{data.desc}</div>
        <div className="flex pt-4 text-gray-500 text-xs">
          <img
            className={`${styles.CalenderIcon} mr-1`}
            alt={data.title}
            src={getStaticMediaUrl(notificationDate)}
          />
          <div className="text-xs">{new Date(data.date).toLocaleDateString()}</div>
        </div>
      </div>
    </>
  );
}
