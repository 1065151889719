import { JSONSchema4 } from 'json-schema';
/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface GetMeApiSchemaContract {
  id: number;
  name: string;
  signupTitle?: string | null;
  hasMultipleOrgs?: boolean;
  currentOrganization?: {
    id?: number;
    name?: string | null;
    pan?: string | null;
    tan?: string | null;
    gstin: string | null;
    cin?: string | null;
    llpin?: string | null;
    brandName?: string | null;
    logo?: string | null;
    isPayrollEnabled?: boolean | null;
    payrollDate?: number | null;
    advanceSalaryEnabled?: boolean | null;
    autoExecutePayroll?: boolean;
    authorizedSignatory?: {
      pan?: string | null;
      name?: string | null;
      [k: string]: unknown;
    } | null;
    data?: {
      address1?: string | null;
      address2?: string | null;
      [k: string]: unknown;
    } | null;
    pincode?: string | null;
    state?: number | null;
    paymentOptions?: {
      payContractors?: boolean | null;
      payTds?: boolean | null;
      enableTdsPaymentForContractors?: boolean | null;
      payPt?: boolean | null;
      payPf?: boolean | null;
      includeEmployerPfContributionInCtc?: boolean | null;
      includePfAdminChargeInCtc?: boolean | null;
      useBasicOnlyForPf?: boolean | null;
      restrictPfToLimit?: boolean | null;
      payEsi?: boolean | null;
      includePayrollAdditionsToESIWages?: boolean | null;
      includeEmployerEsiContributionInCtc?: boolean | null;
      start24QFrom?: string | null;
      start26QFrom?: string | null;
      [k: string]: unknown;
    };
    planName?: string;
    kycStatus?: number | null;
    isKycRestricted?: boolean;
    isRXIntegrated?: boolean;
    isTwoFactorAuthenticationEnabled?: boolean;
    isTwoFactorAuthenticationEnabledForAdmins?: boolean;
    employeeDetails?: {
      id: number;
      peopleId: number;
      organizationId: number;
      hireDate: string | null;
      employeeType: string | null;
      userRoleId: number | null;
      title: string | null;
      isActive: boolean | null;
      deactivateDate: string | null;
      permissions?: string[];
      reporteesCount: number;
      uiPreferences?: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` ".*".
         */
        [k: string]: unknown;
      } | null;
      isPf?: boolean | null;
      [k: string]: unknown;
    };
    isActive?: boolean;
    features?: {
      /**
       * This interface was referenced by `undefined`'s JSON-Schema definition
       * via the `patternProperty` ".*".
       */
      [k: string]: boolean;
    };
    departments?: string[];
    locations?: {
      code?: number;
      name?: string;
      [k: string]: unknown;
    }[];
    actionsWithActiveWorkflows?: number[];
    salaryStructureConfig: {
      customAllowances?: {
        name: string;
        type: number;
        amount: number | null;
        taxable: number;
        percentage: number | null;
        [k: string]: unknown;
      }[];
      fbpDefaultTaxable?: boolean;
      useDefault?: boolean;
      percBasic?: number | null;
      percHra?: number | null;
      percSpecialAllowance?: number | null;
      percLta?: number | null;
      percDa?: number | null;
      percNpsLimit?: number | null;
      [k: string]: unknown;
    };
    defaultSalaryStructureConfig?: {
      basicPercentage?: number;
      hraPercentage?: number;
      specialAllowancePercentage?: number;
      ltaPercentage?: number;
      daPercentage?: number;
      [k: string]: unknown;
    };
    isPt: boolean | null;
    isPf: boolean | null;
    isEsi: boolean | null;
    auto24q?: boolean | null;
    auto26q?: boolean | null;
    externalCredentials?: {
      esicUser?: string | null;
      esicPass?: string | null;
      pfUser?: string | null;
      pfPass?: string | null;
      tracesUser?: string | null;
      tracesPass?: string | null;
      itUser?: string | null;
      itPass?: string | null;
      ptInfo?: {
        /**
         * This interface was referenced by `undefined`'s JSON-Schema definition
         * via the `patternProperty` "^.*$".
         */
        [k: string]: {
          ptUser?: string | null;
          ptPass?: string | null;
          ptAdditionalInfo?: string | null;
          [k: string]: unknown;
        };
      };
      [k: string]: unknown;
    };
    onboardingConfig?: {
      isCompanyAdressSetupComplete?: boolean | null;
      isCompanyTaxSetupComplete?: boolean | null;
      isPayrollDateSetupComplete?: boolean | null;
      isSalaryStructureSetupComplete?: boolean | null;
      isTDSSetupComplete?: boolean | null;
      isPTSetupComplete?: boolean | null;
      isPFSetupComplete?: boolean | null;
      isESICSetupComplete?: boolean | null;
      hasInvitedCA?: boolean | null;
      isAddressConfirmedFromGstin?: boolean | null;
      isNameConfirmedFromPan?: boolean | null;
      isBankVerificationComplete?: boolean | null;
      isDocumentVerificationComplete?: boolean | null;
      bankAccountVerificationType?: string | null;
      kycFlowType?: string | null;
      signupType?: string | null;
      isActivatedOnRazorpay?: boolean | null;
      [k: string]: unknown;
    };
    noOfEmployees?: number;
    type?: number | null;
    levelOneKyc?: {
      completedOn?: number;
      status?: boolean;
      typeOfPan?: string;
      [k: string]: unknown;
    } | null;
    signupNumEmployees?: string | null;
    razorpayMid?: string | null;
    npsConfig?: {
      isNpsEnabled: boolean;
      [k: string]: unknown;
    };
    disablePfPaymentsFlag?: boolean;
    [k: string]: unknown;
  } | null;
  specialPlatformPermissions?: string[];
  /**
   * JWT token of loggedin user. It will have same lifetime as session.
   */
  accessToken?: string;
  appConfig?: {
    slackClientId?: string;
    slackScopes?: string;
    defaultSalaryCustomAllowancesAllowed?: number;
    referenceLinks?: {
      GSTINInvoiceKnowledgeBaseLink?: string;
      verifyTanLink?: string;
      verifyPanLink?: string;
      linkTraces?: string;
      linkPf?: string;
      linkHra?: string;
      linkEsic?: string;
      link24Q?: string;
      link26Q?: string;
      pfContributionCalculation?: string;
      itRegistrationStepsLink?: string;
      payrollFinalizationError?: string;
      payrollAfterFilingAdvisory?: string;
      jibbleSignupLink?: string;
      jibbleApiPageLink?: string;
      jibbleDashboardLink?: string;
      jibblePeopleLink?: string;
      jibbleTimesheetLink?: string;
      [k: string]: unknown;
    };
    flashMessages?: {
      [k: string]: unknown;
    };
    [k: string]: unknown;
  };
  [k: string]: unknown;
}

export default <JSONSchema4>{
  type: 'object',
  title: '',
  properties: {
    id: { type: 'number' },
    name: { type: 'string' },
    signupTitle: { type: ['string', 'null'] },
    hasMultipleOrgs: { type: ['boolean'] },
    currentOrganization: {
      type: ['object', 'null'],
      properties: {
        id: { type: 'integer' },
        name: { type: ['string', 'null'] },
        pan: { type: ['string', 'null'] },
        tan: { type: ['string', 'null'] },
        gstin: { type: ['string', 'null'] },
        cin: { type: ['string', 'null'] },
        llpin: { type: ['string', 'null'] },
        brandName: { type: ['string', 'null'] },
        logo: { type: ['string', 'null'] },
        isPayrollEnabled: { type: ['boolean', 'null'] },
        payrollDate: { type: ['integer', 'null'] },
        advanceSalaryEnabled: { type: ['boolean', 'null'] },
        autoExecutePayroll: { type: ['boolean'] },
        authorizedSignatory: {
          type: ['object', 'null'],
          properties: { pan: { type: ['string', 'null'] }, name: { type: ['string', 'null'] } },
        },
        data: {
          type: ['object', 'null'],
          properties: {
            address1: { type: ['string', 'null'] },
            address2: { type: ['string', 'null'] },
          },
        },
        pincode: { type: ['string', 'null'] },
        state: { type: ['integer', 'null'] },
        paymentOptions: {
          type: 'object',
          properties: {
            payContractors: { type: ['boolean', 'null'] },
            payTds: { type: ['boolean', 'null'] },
            enableTdsPaymentForContractors: { type: ['boolean', 'null'] },
            payPt: { type: ['boolean', 'null'] },
            payPf: { type: ['boolean', 'null'] },
            includeEmployerPfContributionInCtc: { type: ['boolean', 'null'] },
            includePfAdminChargeInCtc: { type: ['boolean', 'null'] },
            useBasicOnlyForPf: { type: ['boolean', 'null'] },
            restrictPfToLimit: { type: ['boolean', 'null'] },
            payEsi: { type: ['boolean', 'null'] },
            includePayrollAdditionsToESIWages: { type: ['boolean', 'null'] },
            includeEmployerEsiContributionInCtc: { type: ['boolean', 'null'] },
            start24QFrom: { type: ['string', 'null'] },
            start26QFrom: { type: ['string', 'null'] },
          },
        },
        planName: { type: 'string' },
        kycStatus: { type: ['integer', 'null'] },
        isKycRestricted: { type: 'boolean' },
        isRXIntegrated: { type: 'boolean' },
        isTwoFactorAuthenticationEnabled: { type: 'boolean' },
        isTwoFactorAuthenticationEnabledForAdmins: { type: 'boolean' },
        employeeDetails: {
          type: 'object',
          properties: {
            id: { type: 'integer' },
            peopleId: { type: 'integer' },
            organizationId: { type: 'integer' },
            hireDate: { type: ['string', 'null'] },
            employeeType: { type: ['string', 'null'] },
            userRoleId: { type: ['integer', 'null'] },
            title: { type: ['string', 'null'] },
            isActive: { type: ['boolean', 'null'] },
            deactivateDate: { type: ['string', 'null'] },
            permissions: { type: 'array', items: { type: 'string' } },
            reporteesCount: { type: 'integer' },
            uiPreferences: { type: ['object', 'null'], patternProperties: { '.*': {} } },
            isPf: { type: ['boolean', 'null'] },
          },
          required: [
            'id',
            'peopleId',
            'organizationId',
            'hireDate',
            'employeeType',
            'userRoleId',
            'title',
            'isActive',
            'deactivateDate',
            'reporteesCount',
          ],
        },
        isActive: { type: 'boolean' },
        features: { type: 'object', patternProperties: { '.*': { type: 'boolean' } } },
        departments: { type: 'array', items: { type: 'string' } },
        locations: {
          type: 'array',
          items: {
            type: ['object'],
            properties: { code: { type: 'number' }, name: { type: 'string' } },
          },
        },
        actionsWithActiveWorkflows: { type: 'array', items: { type: 'number' } },
        salaryStructureConfig: {
          type: 'object',
          properties: {
            customAllowances: {
              type: 'array',
              items: {
                type: 'object',
                properties: {
                  name: { type: 'string' },
                  type: { type: 'integer' },
                  amount: { type: ['integer', 'null'] },
                  taxable: { type: 'integer' },
                  percentage: { type: ['integer', 'null'] },
                },
                required: ['name', 'type', 'taxable', 'amount', 'percentage'],
              },
            },
            fbpDefaultTaxable: { type: 'boolean' },
            useDefault: { type: 'boolean' },
            percBasic: { type: ['integer', 'null'] },
            percHra: { type: ['integer', 'null'] },
            percSpecialAllowance: { type: ['integer', 'null'] },
            percLta: { type: ['integer', 'null'] },
            percDa: { type: ['integer', 'null'] },
            percNpsLimit: { type: ['integer', 'null'] },
          },
        },
        defaultSalaryStructureConfig: {
          type: 'object',
          properties: {
            basicPercentage: { type: 'integer' },
            hraPercentage: { type: 'integer' },
            specialAllowancePercentage: { type: 'integer' },
            ltaPercentage: { type: 'integer' },
            daPercentage: { type: 'integer' },
          },
        },
        isPt: { type: ['boolean', 'null'] },
        isPf: { type: ['boolean', 'null'] },
        isEsi: { type: ['boolean', 'null'] },
        auto24q: { type: ['boolean', 'null'] },
        auto26q: { type: ['boolean', 'null'] },
        externalCredentials: {
          type: 'object',
          properties: {
            esicUser: { type: ['string', 'null'] },
            esicPass: { type: ['string', 'null'] },
            pfUser: { type: ['string', 'null'] },
            pfPass: { type: ['string', 'null'] },
            tracesUser: { type: ['string', 'null'] },
            tracesPass: { type: ['string', 'null'] },
            itUser: { type: ['string', 'null'] },
            itPass: { type: ['string', 'null'] },
            ptInfo: {
              type: 'object',
              patternProperties: {
                '^.*$': {
                  properties: {
                    ptUser: { type: ['string', 'null'] },
                    ptPass: { type: ['string', 'null'] },
                    ptAdditionalInfo: { type: ['string', 'null'] },
                  },
                },
              },
            },
          },
        },
        onboardingConfig: {
          type: 'object',
          properties: {
            isCompanyAdressSetupComplete: { type: ['boolean', 'null'] },
            isCompanyTaxSetupComplete: { type: ['boolean', 'null'] },
            isPayrollDateSetupComplete: { type: ['boolean', 'null'] },
            isSalaryStructureSetupComplete: { type: ['boolean', 'null'] },
            isTDSSetupComplete: { type: ['boolean', 'null'] },
            isPTSetupComplete: { type: ['boolean', 'null'] },
            isPFSetupComplete: { type: ['boolean', 'null'] },
            isESICSetupComplete: { type: ['boolean', 'null'] },
            hasInvitedCA: { type: ['boolean', 'null'] },
            isAddressConfirmedFromGstin: { type: ['boolean', 'null'] },
            isNameConfirmedFromPan: { type: ['boolean', 'null'] },
            isBankVerificationComplete: { type: ['boolean', 'null'] },
            isDocumentVerificationComplete: { type: ['boolean', 'null'] },
            bankAccountVerificationType: { type: ['string', 'null'] },
            kycFlowType: { type: ['string', 'null'] },
            signupType: { type: ['string', 'null'] },
            isActivatedOnRazorpay: { type: ['boolean', 'null'] },
          },
        },
        noOfEmployees: { type: 'number' },
        type: { type: ['number', 'null'] },
        levelOneKyc: {
          type: ['object', 'null'],
          properties: {
            completedOn: { type: ['number'] },
            status: { type: ['boolean'] },
            typeOfPan: { type: ['string'] },
          },
        },
        signupNumEmployees: { type: ['string', 'null'] },
        razorpayMid: { type: ['string', 'null'] },
        npsConfig: {
          type: 'object',
          properties: { isNpsEnabled: { type: ['boolean'] } },
          required: ['isNpsEnabled'],
        },
        disablePfPaymentsFlag: { type: ['boolean'] },
      },
      required: ['gstin', 'salaryStructureConfig', 'isPt', 'isPf', 'isEsi'],
    },
    specialPlatformPermissions: { type: 'array', items: { type: 'string' } },
    accessToken: {
      type: 'string',
      description: 'JWT token of loggedin user. It will have same lifetime as session.',
    },
    appConfig: {
      type: 'object',
      properties: {
        slackClientId: { type: 'string' },
        slackScopes: { type: 'string' },
        defaultSalaryCustomAllowancesAllowed: { type: 'integer' },
        referenceLinks: {
          type: 'object',
          properties: {
            GSTINInvoiceKnowledgeBaseLink: { type: 'string' },
            verifyTanLink: { type: 'string' },
            verifyPanLink: { type: 'string' },
            linkTraces: { type: 'string' },
            linkPf: { type: 'string' },
            linkHra: { type: 'string' },
            linkEsic: { type: 'string' },
            link24Q: { type: 'string' },
            link26Q: { type: 'string' },
            pfContributionCalculation: { type: 'string' },
            itRegistrationStepsLink: { type: 'string' },
            payrollFinalizationError: { type: 'string' },
            payrollAfterFilingAdvisory: { type: 'string' },
            jibbleSignupLink: { type: 'string' },
            jibbleApiPageLink: { type: 'string' },
            jibbleDashboardLink: { type: 'string' },
            jibblePeopleLink: { type: 'string' },
            jibbleTimesheetLink: { type: 'string' },
          },
        },
        flashMessages: { type: ['object'] },
      },
    },
  },
  required: ['id', 'name'],
};
