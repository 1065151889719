import { gql } from 'graphql-request';

export const getWorkflowRequestQuery = gql`
  query PayrollWorkflowRequest($workflowRequestId: Int!) {
    PayrollWorkflowRequest(id: $workflowRequestId) {
      workflowRequestsData {
        id
        status
        peopleId
        person {
          peopleId
          name
          idPrefix
          employeeId
          employeeType
        }
        organizationId
        type
        oldData
        newData
        createdAt
        status
        updatedAt
        updatedBy
        updatedByPerson {
          peopleId
          name
          idPrefix
          employeeId
          employeeType
        }
        deletedAt
        pendingOnLevel
        source
        forPeopleId
        forPerson {
          peopleId
          name
          idPrefix
          employeeId
          employeeType
        }
        payload
      }
      activityLog {
        level1 {
          id
          activity
          byPeopleId
          byPerson {
            peopleId
            name
            idPrefix
            employeeId
            employeeType
          }
          level
          remarks
          updatedAt
          workflowId
          changesStagingId
        }
        level2 {
          id
          activity
          byPeopleId
          byPerson {
            peopleId
            name
            idPrefix
            employeeId
            employeeType
          }
          level
          remarks
          updatedAt
          workflowId
          changesStagingId
        }
      }
      workflowData {
        id
        organizationId
        actionType
        status
        approvers {
          levels {
            level
            levelId
            minApprovals
            peopleIds
            people {
              peopleId
              name
              idPrefix
              employeeId
              employeeType
            }
          }
        }
        version
        createdAt
        createdBy
        createdByPerson {
          peopleId
          name
          idPrefix
          employeeId
          employeeType
        }
        deletedBy
        deletedByPerson {
          peopleId
          name
          idPrefix
          employeeId
          employeeType
        }
        updatedAt
        deletedAt
      }
    }
  }
`;

export const getWorkflowRequestsQuery = gql`
  query PayrollWorkflowRequests(
    $fromDate: String
    $toDate: String
    $page: Int
    $limit: Int
    $isInitiatedByMe: Int
    $isMyApproval: Int
    $status: String
    $level: Int
    $actionType: Int!
  ) {
    PayrollWorkflowRequests(
      fromDate: $fromDate
      toDate: $toDate
      page: $page
      limit: $limit
      isInitiatedByMe: $isInitiatedByMe
      isMyApproval: $isMyApproval
      status: $status
      level: $level
      actionType: $actionType
    ) {
      perPage
      lastPage
      total
      to
      from
      data {
        id
        peopleId
        person {
          peopleId
          name
          employeeId
          idPrefix
          employeeType
        }
        organizationId
        type
        oldData
        newData
        createdAt
        status
        updatedAt
        updatedBy
        updatedByPerson {
          peopleId
          name
          employeeId
          idPrefix
          employeeType
        }
        deletedAt
        pendingOnLevel
        source
        forPeopleId
        forPerson {
          peopleId
          name
          employeeId
          idPrefix
          employeeType
        }
        payload
      }
    }
  }
`;
