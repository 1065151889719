const PRODUCTION = 1;
const DEMO = 2;
const STAGE = 3;
const DEV = 4;
const LOCAL = 5;

const hostMap = {
  [PRODUCTION]: 'payroll.razorpay.com',
  [DEMO]: 'payroll-demo.razorpay.com',
  [STAGE]: 'opfin.np.razorpay.in',
  [DEV]: 'opfin-dev.stage.razorpay.in/',
  [LOCAL]: 'app.localopfin.com',
};

const envName = {
  [PRODUCTION]: 'production',
  [DEMO]: 'demo',
  [STAGE]: 'stage',
  [DEV]: 'dev',
  [LOCAL]: 'local',
}

function getEnvironment() {
  return window.location.host;
}

export function isProduction() {
  return getEnvironment() === hostMap[PRODUCTION];
}

export function isStage() {
  return getEnvironment() === hostMap[STAGE];
} 

export function isDemo() {
  return getEnvironment() === hostMap[DEMO];
}

export function isDev() {
  return getEnvironment() === hostMap[DEV];
}

export function isLocal() {
  return getEnvironment() === hostMap[LOCAL];
}

export function getEnvironmentName() {
  const environment = getEnvironment();
  switch (environment) {
    case hostMap[PRODUCTION]:
      return envName[PRODUCTION];
    case hostMap[DEMO]:
      return envName[DEMO];
    case hostMap[STAGE]:
      return envName[STAGE];
    case hostMap[DEV]:
      return envName[DEV];
    case hostMap[LOCAL]:
      return envName[LOCAL];
    default:
      return envName[STAGE];
  }
}

export default getEnvironment;
